<template>
  <v-main app>
    <v-dialog
      v-model="isLock"
      :max-width="920"
      persistent
    >
      <v-card class="pa-6" flat>
        <div class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="error"
                v-bind="attrs"
                v-on="on"
                @click="logoutUser"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Sign Out</span>
          </v-tooltip>
        </div>
        <v-card-text>
          <v-row>
            <v-spacer />
            <v-col cols="auto">
              <img width="300px" src="@/assets/images/illustrations/business_deal.svg">
            </v-col>
            <v-spacer />
          </v-row>
          <!--<v-row dense>
            <v-spacer />
            <v-col cols="auto">
              <h1>No Access</h1>
            </v-col>
            <v-spacer />
          </v-row>-->
          <v-row>
            <v-spacer />
            <v-col cols="auto">
              <div class="text-center">
                <h1>Thank you for subscribing!</h1>
                <div class="text-body-1 mb-4">Welcome to our enterprise PTaaS platform. In order to provide you with a customized experience, our team will prepare a personalized proposal to initiate your first penetration testing project. A member of our sales team will contact you shortly to guide you through the process.</div>
                <h4>Want to speed things up?</h4>
                <div class="text-body-1 mt-2">If you'd like to move forward more quickly, you can expedite the process by using our simplified Penetration Testing Project Scoping Form. By providing us with specific information about your project, we can quickly prepare a detailed proposal. Click the button below to get started:</div>
                <v-btn class="mt-4 mb-4" depressed color="primary" @click="goTo('https://www.vumetric.com/pentest-quote/?email=' + me.email + '&utm_source=ptaas&utm_medium=popup&utm_campaign=confirmation')">CREATE MY FIRST PROJECT</v-btn>
                <div class="text-body-1 mt-4">If you have any questions or need further assistance, <a href="#" @click="goTo('https://www.vumetric.com/contact-us/')">our support team is ready to help</a>. Feel free to contact us at any time!</div>
              </div>
            </v-col>
            <v-spacer />
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container
      v-if="!isLock"
      fluid
      class="pt-6"
    >
      <!-- If using vue-router -->

      <!-- <customizer /> -->
      <v-expand-transition mode="out-in">
        <router-view />
      </v-expand-transition>
    </v-container>
    <div class="flex-grow-1" />
    <app-footer />
  </v-main>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import snackbarPlugin from "@/plugins/snackbar"
  import UserService from '@/services/user.service'

  export default {
    components: {
      AppFooter: () => import('./Footer'),
      // Customizer: () => import("@/layouts/common-drawer/Customizer"),
    },
    props: ['me'],
    data: () => ({
      isLock: false,
    }),
    computed: {
      ...mapActions([
        "signOut",
      ]),
      ...mapGetters(['getThemeMode']),
    },
    mounted () {
      if (this.me.client ? this.me.client.isLocked : false) {
        this.isLock = true
      } else {
        this.isLock = false
      }
    },
    methods: {
      goTo (link) {
        if (confirm(`You will be redirected to an external page of this website at: ${link}`) === true) {
          window.location.href = link
        }
      },
      logoutUser () {
        snackbarPlugin.showLoading('Disconnecting ...')
        this.$store.dispatch("auth/logout", this.user).then(() => {
          this.$router.push("/login")
          setTimeout(() => {
            snackbarPlugin.showSuccess('You are successfully logged out !')
          }, 1000)
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
@media print {
  .v-main {
        padding: 0 !important;
    }
}
.v-main__wrap {
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}


.v-overlay {
    backdrop-filter: blur(5px); /* Add your preferred blur amount */
}

</style>
